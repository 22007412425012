import {
  CookieCategory,
  CookieSettings,
  ModalSettings as MS,
  Script,
} from '@/components/react-gw-components/src/components/CookieConsent/definitions'
export const Categories: Array<CookieCategory> = [
  {
    id: 1,
    name: {
      en: 'Strictly necessary cookies',
      fi: 'Välttämättömät evästeet',
      sv: 'Nödvändiga cookies',
    },
    enabled: true,
    description: {
      en: 'Necessary cookies are cookies that are required in order for this page to function correctly. Regarding this page, necessary cookies are used only to remember what cookie settings you have chosen.',
      fi: 'Välttämättömät evästeet ovat sellaisia evästeitä, joita ilman sivuston käyttäminen ei olisi mahdollista. Tämän sivuston tapauksessa välttämättömiä evästeitä käytetään vain tallentamaan tieto siitä, mitä evästevalintoja olet tehnyt.',
      sv: 'Nödvändiga cookies är sådana som krävs för normal användning av denna hemsida. De cookies som krävs på denna hemsida används endast för att komma ihåg de val du gör om de cookies du accepterar eller avvisar.',
    },
    cookies: [],
    isChangeable: false,
  },
  {
    id: 2,
    name: {
      en: 'Marketing cookies',
      fi: 'Markkinoinnin evästeet',
      sv: 'Marketing cookies',
    },
    enabled: false,
    description: {
      en: 'This website uses Snoobi Analytics -analytics tools to track user behaviour. This information is used to generate anonymous information on how the website is used so we may further improve the website. Your personal information is disclosed to a third party: Snoobi uses this information to target their marketing based on your behavior according to their own privacy policy.',
      fi: 'Sivusto käyttää Snoobi Analytics -analytiikkatyökaluja kävijöiden käyttäytymisen seurantaan. Käytämme näitä tietoja kehittääksemme Snoobi ja seurataksemme kävijöiden käyttäytymistä. Hyväksyessäsi nämä evästeet tietosi luovutetaan kolmannelle osapuolelle, ja Snoobi käyttää tietojasi oman tietosuojakäytäntönsä mukaisesti.',
      sv: 'Webbplatsen använder Snoobi Analytics -analytiklösningar för att spåra webbplatsanvändning. Utifrån denna information genererar vi anonymiserad information om hur sidan används, och vi förbättrar sidan ytterligare baserat på denna information. Din information kommer att vidarebefordras till tredje part: Snoobi använder informationen som samlas in i detta sammanhang för att rikta sin egen marknadsföring i enlighet med sin egen sekretesspolicy.',
    },
    cookies: [],
    isChangeable: true,
  },
]

export const Scripts: Array<Script> = [
  {
    name: 'necessary',
    script: '',
    cookieCategory: 1,
    cookies: [],
  },
  {
    name: 'Snoobi Analytics',
    script:
      "var _saq = _saq || []; (function() { var account = 'tkl_fi'; var snbpagename = ''; var snbsection = ''; var snbcookies = 'session'; var anchors = '1'; var snbscript = document.createElement('script'); snbscript.type = 'text/javascript'; snbscript.async= true; snbscript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'eu2.snoobi.com/?account=' + account + '&page_name=' + snbpagename + '&section=' + snbsection + '&anchors=' + anchors + '&cookies=' + snbcookies; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(snbscript, s);})();",
    cookieCategory: 2,
    cookies: ['SnoobiID', 'Snoobisession_tkl_fi', 'Snoobisession_account', 'Snoobi30minute_account', 'Snoobi', 'Snoobi_Rand', 'Snoop_testi', 'SnoobiId'],
  },
]

export const DefaultSettings: Array<CookieSettings> = [
  {
    id: 1,
    enabled: true,
  },
  {
    id: 2,
    enabled: false,
  },
]

export const ModalSettings: MS = {
  visible: false,
  title: {
    en: 'Cookie overview',
    fi: 'Evästeistä yleisesti',
    sv: 'Cookies allmänt',
  },
  description: {
    en: '...',
    fi: 'Käytämme sivustolla evästeitä. Evästeet ovat pieniä tekstitiedostoja, jotka asetetaan käyttäjän päätelaitteelle. Ilman evästeitä ei ole mahdollista luotettavasti erottaa käyttäjiä toisistaan. Voit tästä näkymästä lukea tarkemmin käyttämistämme evästeistä ja valita, mitkä evästeet haluat hyväksyä tai hylätä',
    sv: '...',
  },
  buttons: {
    ui: {
      isRounded: true,
    },
    yes: {
      en: 'Accept',
      fi: 'Hyväksy',
      sv: 'Godkänn',
    },
    no: {
      en: 'Decline',
      fi: 'Hylkää',
      sv: 'Neka',
    },
    footer: {
      enable_all: {
        en: 'Enable all',
        fi: 'Hyväksy kaikki',
        sv: 'Godkänn alla',
      },
      disable_all: {
        en: 'Disable all',
        fi: 'Hylkää kaikki',
        sv: 'Neka alla',
      },
      refresh: {
        en: 'Refresh',
        fi: 'Päivitä',
        sv: 'Uppdatera',
      },
    },
  },
}

export const BannerContent = {
  banner: {
    en: 'This website uses cookies to gather information on its visitors. You can find out more about which cookies we are using or switch them off from the',
    fi: 'Käytämme verkkosivustolla evästeitä, joilla keräämme yksilöityä tietoa käyttäjästä. Voit tutustua käyttämiimme evästeisiin sekä hyväksyä niitä yksitellen',
    sv: 'Vi använder cookies på webbplatsen för att samla in personligt identifierbar information om dig. Du kan bekanta dig med de cookies vi använder och acceptera dem enskilt via',
  },
  buttons: {
    accept: {
      en: 'Accept',
      fi: 'Hyväksy',
      sv: 'Godkänn',
    },
    decline: {
      en: 'Decline',
      fi: 'Hylkää',
      sv: 'Neka',
    },
    settings: {
      en: 'settings',
      fi: 'asetuksista',
      sv: 'inställningarna',
    },
  },
}

export const Notifications = {
  all_enabled: {
    en: 'All cookies enabled',
    fi: 'Evästeet hyväksytty',
    sv: 'Alla cookies godkända',
  },
  all_disabled: {
    en: 'All cookies disabled',
    fi: 'Evästeet hylätty',
    sv: 'Alla cookies nekade',
  },
  refresh: {
    en: 'Click to reload',
    fi: 'Lataa sivu uudelleen',
    sv: 'Ladda sidan om',
  },
  updated: {
    en: 'Settings saved',
    fi: 'Asetukset tallennettu',
    sv: 'Inställningarna sparade',
  },
  not_changeable: {
    en: 'Category not changeable',
    fi: 'Kategoriaa ei voi muuttaa',
    sv: 'Kategorin kan inte ändras',
  },
}
