import React from 'react'
import RootWrapper from '@/components/RootWrapper'
import CookieContainer from '@/components/Layout/Layout/CookieContainer'
import { CookieConsent } from '@/components/react-gw-components'
import { Categories, Scripts, DefaultSettings, ModalSettings, BannerContent } from './cookieconfig'
import { Helmet } from 'react-helmet'

export const wrapRootElement = ({ element }) => <RootWrapper>{element}</RootWrapper>

export const wrapPageElement = ({ element, props }) => {
  //console.log(Scripts)
  const lang = props.pageContext?.postlang || 'fi'
  const notifications = {
    all_enabled: {
      en: 'All cookies enabled',
      fi: 'Evästeet hyväksytty',
    },
    all_disabled: {
      en: 'All cookies disabled',
      fi: 'Evästeet hylätty',
    },
    refresh: {
      en: 'Click to reload',
      fi: 'Lataa sivu uudelleen',
    },
    updated: {
      en: 'Settings saved',
      fi: 'Asetukset tallennettu',
    },
    not_changeable: {
      en: 'Category not changeable',
      fi: 'Kategoriaa ei voi muuttaa',
    },
  }
  return (
    <CookieContainer {...props}>
      {element}
      <CookieConsent
        notifications={notifications}
        acceptButtonText={BannerContent.buttons.accept[lang]}
        declineButtonText={BannerContent.buttons.decline[lang]}
        settingsLabel={BannerContent.buttons.settings[lang]}
        bannerContent={BannerContent.banner[lang]}
        modalSettings={ModalSettings}
        defaultUserChoices={DefaultSettings}
        currentLang={lang}
        expirationTime={365}
        categories={Categories}
        scripts={Scripts}
      />
    </CookieContainer>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
