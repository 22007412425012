module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","name":"Teknisen Kaupan Liitto ry","short_name":"TKL","description":"Teknisen Kaupan Liitto ry","lang":"fi","icon":"src/images/favicon-32x32.png","theme_color":"#191919","background_color":"#191919","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f94dddcadebe8001e3cf0ed8238319a"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://hallinta.tekninen.fi/graphql","schema":{"typePrefix":"Wp","timeout":60000,"requestConcurrency":5,"perPage":20,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":false},{"name":"url","indexed":false,"resolver":"fields.slug","store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Etusivu","crumbSeparator":" / "}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
