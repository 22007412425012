exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-haku-tsx": () => import("./../../../src/pages/haku.tsx" /* webpackChunkName: "component---src-pages-haku-tsx" */),
  "component---src-templates-careers-archive-tsx": () => import("./../../../src/templates/careers/Archive.tsx" /* webpackChunkName: "component---src-templates-careers-archive-tsx" */),
  "component---src-templates-careers-single-tsx": () => import("./../../../src/templates/careers/Single.tsx" /* webpackChunkName: "component---src-templates-careers-single-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-districts-tsx": () => import("./../../../src/templates/Districts.tsx" /* webpackChunkName: "component---src-templates-districts-tsx" */),
  "component---src-templates-events-events-tsx": () => import("./../../../src/templates/events/Events.tsx" /* webpackChunkName: "component---src-templates-events-events-tsx" */),
  "component---src-templates-events-single-tsx": () => import("./../../../src/templates/events/Single.tsx" /* webpackChunkName: "component---src-templates-events-single-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news/Archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/news/Single.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-sections-archive-tsx": () => import("./../../../src/templates/sections/Archive.tsx" /* webpackChunkName: "component---src-templates-sections-archive-tsx" */),
  "component---src-templates-sections-single-tsx": () => import("./../../../src/templates/sections/Single.tsx" /* webpackChunkName: "component---src-templates-sections-single-tsx" */),
  "component---src-templates-staff-tsx": () => import("./../../../src/templates/Staff.tsx" /* webpackChunkName: "component---src-templates-staff-tsx" */)
}

